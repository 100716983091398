<template>
  <div class="card">
    <div class="card-body">
      <div class="card-title">Alignement
        <span aria-hidden="true" :class="[ estAlignementValide() ? 'valide' : 'invalide', 'fas statut']"></span>
      </div>
      <div class="d-flex">
        <div v-if="contexte !== 'repechage'" class="colonne-sommaire" :class="{invalide: !estNbAttaquantsValide}">
          <span class="libelle">Attaquants</span>
          <span class="valeur">{{nbAttaquants}}/12</span>
        </div>
        <div v-if="contexte !== 'repechage'" class="colonne-sommaire" :class="{invalide: !estNbDefenseursValide}">
          <span class="libelle">Défenseurs</span>
          <span class="valeur">{{nbDefenseurs}}/3</span>
        </div>
        <div v-if="contexte !== 'repechage'" class="colonne-sommaire" :class="{invalide: !estNbGardiensValide}">
          <span class="libelle">Gardiens</span>
          <span class="valeur">{{nbGardiens}}/2</span>
        </div>
        <div v-if="contexte === 'repechage'" class="colonne-sommaire" :class="{invalide: !estNbJoueursValide}">
          <span class="libelle">Nb. Joueurs</span>
          <span class="valeur">{{nbJoueurs}}/29</span>
        </div>
        <div v-if="contexte !== 'mouvement-joueurs'" class="colonne-sommaire" :class="{invalide: !estNbElcValide}">
          <span class="libelle">ELC</span>
          <span class="valeur">{{nbElc}}/5</span>
        </div>
      </div>   
      <div v-if="contexte === 'repechage' && !estAlignementValide()" class="messages text-danger">
        <span>Surplus de {{nbJoueurs - 28}} joueur(s) pour drafter</span>
      </div>             
    </div>
  </div>                   
</template>

<script>
import { computed } from "vue"
export default {
  name: "SommaireSalaire",
  props: {
    contexte: String,
    nbAttaquants: Number,
    nbDefenseurs: Number, 
    nbGardiens: Number,
    nbJoueurs: Number,
    nbElc: Number,
    estValide: Boolean,
    estContexteMouvementJoueur: Boolean
  },
  setup(props, { emit }) {
//TODO obtenir le nombre max de chaque élément au lieu de coder dur
    const formaterNombre = (nombre) => {
        return nombre.toString().replace(".", ",");
    }
    const estNbAttaquantsValide = computed(() => {
        return !estNbDefenseursValide.value || props.nbDefenseurs + props.nbAttaquants == 15;
    })

    const estNbDefenseursValide = computed(() => {
        return props.nbDefenseurs >= 3;
    })

    const estNbGardiensValide = computed(() => {
        return props.nbGardiens == 2;
    })

    const estNbElcValide = computed(() => {
        return props.nbElc >= 5
    })

    const estNbJoueursValide = computed(() => {
        return props.nbJoueurs <= 28
    })

    const estAlignementValide = () => {
      let estValide
            
      if(props.contexte === 'repechage') {
        estValide = estNbJoueursValide.value
      }
      else if(props.contexte === 'mouvement-joueurs') {      
      estValide = estNbAttaquantsValide.value && estNbDefenseursValide.value && estNbGardiensValide.value
      }
      else {
        estValide = estNbAttaquantsValide.value && estNbDefenseursValide.value && estNbGardiensValide.value && estNbElcValide.value
      }

      emit('estAlignementValide', estValide)
      return estValide;
    }

    return {
      formaterNombre,
      estNbAttaquantsValide,
      estNbDefenseursValide,
      estNbGardiensValide,
      estNbElcValide,
      estAlignementValide,
      estNbJoueursValide
    }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .messages{
    margin-top: 16px;
  }
</style>
