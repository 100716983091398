<template>
  <table :id="id" class="table-joueurs stripe hover row-border" style="width: 100%">
    <thead>
        <tr>                
            <th>Nom</th>
            <th>Pos</th>
            <th>Ville</th>
            <th v-if="contexte === 'joueursTous'">Propriétaire</th>
            <th>Age</th>
            <th>PJ</th>
            <th>1</th>                    
            <th>Pts</th>
            <th>24-25</th>
            <th>25-26</th>                
            <th>26-27</th>
            <th>27-28</th>
            <th class="never">ELC</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="(joueur, index) in joueurs" :key="joueur.Nom" :joueur="joueur" :indexJoueur="index" :class="{selected: joueur.Selected}" >
            <td>
              <span class="d-flex align-items-center">
                <button v-if="contexte==='repechage-drop'" type="button" class="btn btn-sm mouvement" :class="[joueur.Selected ? 'btn-secondary' : 'btn-outline-secondary']" @click="dropperJoueur(joueur)">
                  <span aria-hidden="true" class="fas fa-arrow-down"></span>
                </button>
                <button v-else-if="contexte==='repechage-draft'" type="button" class="btn btn-sm mouvement" :class="[joueur.Selected ? 'btn-secondary' : 'btn-outline-secondary']" @click="drafterJoueur(joueur)">
                  <span aria-hidden="true" class="fas fa-arrow-up"></span>
                </button>
                <template v-else-if="contexte==='echange'">
                    <input class="form-check-input" type="checkbox" value="" v-model="joueur.Selected" :id="'joueur'+joueur.Id" @change="selectionnerJoueur(joueur)">
                    <label class="form-check-label" :for="'joueur'+joueur.Id">
                      {{joueur.Nom}}
                    </label>
                </template>
                <span v-if="contexte!=='echange'">
                  <span>{{joueur.Nom}}</span>
                </span>
                <a v-if="obtenirStatut(joueur) != '' || joueur.Blessure != null || joueur.ELC" tabindex="0" class="statut" role="button" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="focus" data-bs-html="true" :title="joueur.Nom" :data-bs-content="obtenirTexteStatut(joueur)">
                  <span v-if="obtenirStatut(joueur) != ''" :class="[obtenirStatut(joueur), 'statut']"></span>
                  <span v-if="joueur.Blessure != null" class="statut blesse"></span>
                  <span v-if="joueur.ELC" class="statut elc">(ELC)</span>
                </a>
              </span>
            </td>
            <td>{{joueur.Pos}}</td>
            <td>{{joueur.Eqp}}</td>
            <td v-if="contexte === 'joueursTous'">{{joueur.Proprietaire}}</td>
            <td>{{joueur.Age}}</td>
            <td>{{joueur.GP}}</td>
            <td>{{joueur.PHier}}</td>
            <td class="pts">{{joueur.P}}</td>
            <td>{{joueur.Sal}}</td>
            <td>{{joueur.Sal1}}</td>
            <td>{{joueur.Sal2}}</td>
            <td>{{joueur.Sal3}}</td>
            <td class="never">{{joueur.ELC}}</td>
            <td></td>                
        </tr>
      </tbody>
  </table>  
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  name: "Joueurs",
  props: {
    id: String,
    contexte: String,
    joueurs: [Object],
    nomJoueurRepeche: String
  },
  setup(props, { emit }) {

    const obtenirStatut = (joueur) => {
      const mineurs = ["Nhl Non-Roster", "Minor", "Junior", "Loan"];      

      //Status mineurs et taxi squad
      if(mineurs.indexOf(joueur.Statut) >= 1){
        return "mineurs";
      } else if (joueur.Statut == "Taxi Squad"){
        return "taxi";
      }

      return "";
    }

    const obtenirTexteStatut = (joueur) => {

      let statut = obtenirStatut(joueur);
      let texte = "";

      if(statut == "mineurs"){
        texte = "<p>Le joueur est suspendu, dans les ligues mineures ou sans contrat.</p>"
      } else if(statut == "taxi"){
        texte += "<p>Le joueur est sur le taxi squad.</p>"
      }

      if(joueur.Blessure != null){
        var blessure = joueur.Blessure;
//        texte += "<p>Le joueur est blessé.<br/>" + blessure.Description + " - " + blessure.Retour + "<br/>Retour prévu : " + blessure.DateRetour + "</p>";
        texte += "<p>Le joueur est blessé.<br/>" + blessure.Description  + "</p>";
      }

      if(joueur.ELC){
        texte += "<p>Le joueur a un contrat d'entrée (ELC).</p>";
      }
      return texte;
    }

    const dropperJoueur = (joueur) => {
      emit('dropperJoueur', joueur);
    }

    const drafterJoueur = (joueur) => {
      emit('drafterJoueur', joueur);
    }

    const selectionnerJoueur = (joueur) => {
      emit('selectionnerJoueur', joueur);
    }

    return {
      obtenirStatut,
      obtenirTexteStatut,
      dropperJoueur,
      drafterJoueur,
      selectionnerJoueur
    }    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 
  .table-joueurs {
    padding-top: 16px;
    width: 100%;    
  }

  .table-joueurs thead{
    background-color: #EDEDED;
  }

  .table-joueurs .form-check-input {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0;
  }
  .table-joueurs .form-check-label {
    margin-left: 8px;
  }
  .btn.mouvement{
    margin: 0 8px 0 -8px;
  }

  .btn.mouvement .fas{
    width: 14px;
  }

  tr.selected .btn.mouvement .fas:before{
    content: "\f05e";
  }

  a.statut{
    font-family: "Font Awesome 5 Free";
    margin-left: .25rem;
    color:inherit;
    text-decoration: none;
  }

  a.statut > span{
    margin-left: .25rem;
  }

  a.statut .mineurs::before{
    content:"\f0d7";
    color: var(--couleur-erreur);
  }

  a.statut .taxi::before{
    content:"\f1ba";
  }

  a.statut .blesse::before{
    content:"\f0fe";
    color: var(--couleur-erreur);
  }

  a.statut .elc{
    font-family: var(--bs-font-sans-serif);
    font-size: 14px;
  }

  .pts{
    font-weight: 500;
  }

</style>
