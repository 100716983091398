<template>
  <div v-for="(ronde, noRonde, index) in choixAAfficher" :key="index" class="conteneur-choix card">
    <div class="card-body" :class="{'choix-pooler': $props.idPooler}">
      <div class="card-title">Ronde {{noRonde}}</div>
      <ul>
        <template v-if="$props.idPooler">
          <li v-for="(choixRonde, ronde, index ) in ronde" :key="index"><span>{{choixRonde.NomDgInitial}}</span></li>
        </template>
        <template v-else>
          <li v-for="(choixRonde, ronde, index ) in ronde" :key="index" :class="{'pooler-courant': choixRonde.IdDgActuel == idPoolerCourant}"><span>{{choixRonde.NomDgActuel}}</span><span v-if="afficherDgInitial(choixRonde)">&nbsp;({{choixRonde.NomDgInitial}})</span></li>
        </template>
      </ul>        
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { base } from '../commun/base'

export default {
  name: "ChoixRepechage",
  props: {
    idPooler: String
  },
  setup(props) {
    const { obtenirDonnees, groupArrayBy, obtenirIdPoolerCourant } = base()
    let choixAAfficher = ref([])
    let idPoolerCourant = ref("0")

    onMounted(async () => {

      if(!props.idPooler){
        idPoolerCourant.value = await obtenirIdPoolerCourant()            
      }

      choixAAfficher.value = await obtenirChoixAAfficher()
    });

    const obtenirChoixAAfficher = async (force = false) => {           
      const choix = await obtenirDonnees(force, 'choix', 'ChoixRepechage2')  
      if(props.idPooler){
        const choixPooler = choix.filter(choix => choix.IdDgActuel === props.idPooler);
        return groupArrayBy(choixPooler, "Round")
      } else {
        return groupArrayBy(choix, "Round")
      }
    }

    const afficherDgInitial = (choix) => {
      return !(choix.IdDgInitial === choix.IdDgActuel)
    }

    return {
      choixAAfficher,
      idPoolerCourant,
      afficherDgInitial
    }
  }


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  margin: 0 8px 8px 0;
}
.card-body{
  width: 200px;
}

.card-body.choix-pooler{
  width: 150px;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

li{
  line-height: 1;
  margin-bottom: 4px;
  padding: 4px;
  margin-left: -4px;
}

li.pooler-courant{
  background-color: var(--couleur-fond-highlight);
}
li:last-of-type{
  margin-bottom: 0;
}
</style>
