<template>
  <div class="container"> 
    <div class="entete-page">
        <h1 class="titre-page">{{titrePage}}</h1>
    </div>
    <div class="contenu-principal-page">
      <Joueurs :id="idTableJoueurs" :contexte="'joueursTous'" :joueurs="joueursTous"></Joueurs>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import { base } from '../commun/base'
import Joueurs from '../components/Joueurs.vue';
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;


export default {
  name: "JoueursTous",
  props: {
  },

  components: {
    Joueurs
  },

  setup() {   

    const { obtenirDonnees, definirDataTablesJoueurs } = base()
    const idTableJoueurs = "joueursComplet"
    let titrePage = ref("")
    let joueursTous = ref([])
    
    onMounted(async () => {      

      titrePage.value = "Joueurs";
      document.title = document.title + " Joueurs ";      
      joueursTous.value = await obtenirJoueurs();

      nextTick(() => {

        const optionsDT = {
          dom: 'Qlfrtip',
          "searchBuilder": {
            columns: [1,2,3,4,5,7,14]
          },
          "pageLength": 25,
          "paging": true,
          "info": false,
          "searching": true,
          columnDefs: [
                {
                    className: 'control',
                    orderable: false,
                    width: "20px",
                    targets: -1
                },
                { "type": "numeric-comma", "targets": 8 },
                { orderable: false, targets: 8 },
                { orderable: false, targets: 9 },
                { orderable: false, targets: 10 },
                { orderable: false, targets: 11 },
                { orderable: false, visible: false, searchable: true, targets: 12 },
                { responsivePriority: 1, targets: 0 },
                { responsivePriority: 2, targets: 1 },
                { responsivePriority: 6, targets: 2 },
                { responsivePriority: 7, targets: 3 },
                { responsivePriority: 5, targets: 4 },
                { responsivePriority: 4, targets: 5 },
                { responsivePriority: 3, targets: 6 },
                { responsivePriority: 5, targets: 7 }
            ],
            "order": [                
                [7, "desc"]
            ]          
        }

        definirDataTablesJoueurs(`#${idTableJoueurs}`, optionsDT);
      })
    })

    const obtenirJoueurs = async (force = false) => {     
      const joueurs = await obtenirDonnees(force, 'joueursComplet', 'JoueursComplet2')  
      return joueurs.data
    }

    return {
      titrePage,
      idTableJoueurs,
      joueursTous
    };
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .contenu-principal-page{
    background-color: #fff;
  }
  </style>
