<template>
  <div class="container"> 
    <div class="entete-page">
        <h1 class="titre-page">{{titrePage}}</h1>
    </div>
    <div class="contenu-principal-page">
      
      <div class="card mb-48">
        <div class="card-body">
          <div class="card-title d-flex justify-content-between">
            <div>
              <label for="dg">Sélection</label>
              <select id="dg" class="form-select" v-model="idDgSelectionne">
                <option value="0" disabled selected>Sélectionnez un DG...</option>
                <option v-for="pooler in poolers" :value="pooler.Id" :key="pooler.Id">
                {{ pooler.Nom }}
                </option>
              </select>
            </div>
            <button type="button" class="btn btn-secondary" @click="rafraichirContenu(true)">Rafraîchir</button>             
          </div>

          <!-- Sommaire équipe !-->
          <div v-if="equipeDgSelectionne" class="sommaire mt-32">
            <SommaireAlignement :nbJoueurs="nbJoueursEquipeDgSelectionne" :nbElc="equipeDgSelectionne.NbELC" :contexte="'repechage'" @estAlignementValide="estAlignementValideRepechage"></SommaireAlignement>                  
          </div>
          <!-- Confirmation Zone !-->
          <div v-if="joueurSelectionne" class="card mt-32" id="zoneConfirmation">
            <div class="card-body">
              <div class="card-title">
                <span class="joueur-selectionne">Confirmer le {{operation}} de {{joueurSelectionne.Nom}} ?</span>                
              </div>              
              <div class="mt-32">            
                <div class="zone-boutons">
                  <button type="button" class="btn btn-secondary" :disabled="!joueurSelectionne" @click="joueurSelectionne = null">Annuler</button> 
                  <button type="button" class="btn btn-primary" :disabled="!peutRepecher" @click="confirmerMouvement">Confirmer</button> 
                </div>            
              </div>
            </div>
          </div>
        </div>
      </div>  
      <!-- Drop Zone !-->
      <div class="zone-extensible">
        <a class="entete-section" data-bs-toggle="collapse" href="#dropZone" role="button" aria-expanded="false" aria-controls="dropZone">
          <span class="titre">
            <span>Drop Zone</span><span aria-hidden="true" class="icone fas fa-toilet"></span>
          </span>
          <span class="icone-collapse fas"></span>
        </a>          
        <div id="dropZone" class="collapse">
          <div class="conteneur-datatable">
            <Joueurs :id="idTableDropZone" :contexte="estAdmin ? 'repechage-drop' : ''" :joueurs="joueursEquipeDgSelectionne" @dropperJoueur="dropperJoueur"></Joueurs>
          </div>
        </div>
      </div>
      <!-- Draft Zone !-->
      <div class="zone-extensible">
        <a class="entete-section" data-bs-toggle="collapse" href="#draftZone" role="button" aria-expanded="false" aria-controls="draftZone">
          <span class="titre">
            <span>Draft Zone</span><span aria-hidden="true" class="icone fas fa-award"></span>
          </span>
          <span class="icone-collapse fas"></span>
        </a>          
        <div id="draftZone" class="collapse">
          <div class="conteneur-datatable">
            <Joueurs :id="idTableDraftZone" :contexte="estAdmin ? 'repechage-draft' : ''" :joueurs="joueursLibres" @drafterJoueur="drafterJoueur"></Joueurs>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FenetreMessage ref="fenetreMessageMouvementEnCours" :titre="'Traitement en cours...'" :afficherBoutonFermerEntete=false :afficherBoutonPrincipal=!estMouvementJoueurEnCours :corps="obtenirCorpsFenetreMouvementJoueurs"></FenetreMessage>  
  <FenetreMessage ref="fenetreMessageTraitementEnCours" :titre="'Traitement en cours...'" :afficherBoutonFermerEntete=false :afficherBoutonPrincipal=!estTraitementEnCours :corps="obtenirCorpsFenetreTraitementEnCours"></FenetreMessage>  
</template>

<script>
import { ref, computed, onMounted, nextTick } from "vue"
import { base } from '../commun/base'
import Joueurs from '../components/Joueurs.vue'
import FenetreMessage from '../components/FenetreMessage.vue'
import SommaireAlignement from '../components/Equipe/SommaireAlignement.vue'
import jQuery from "jquery"
import * as bootstrap from 'bootstrap'
const $ = jQuery;
window.$ = $;


export default {
  name: "Repechage",
  props: {
  },

  components: {
    Joueurs,
    SommaireAlignement,
    FenetreMessage
  },

  setup() {   


//    const { obtenirDonnees, obtenirTraductionDatatablesFrancais } = base()
    const { obtenirDonnees, definirDataTablesJoueurs, estAdministrateur, obtenirIdPoolerCourant } = base()
    let fenetreMessageMouvementEnCours = ref(null)
    let fenetreMessageTraitementEnCours = ref(null)
    let poolers = ref([])
    let joueursLibres = ref([])    
    let titrePage = ref("")
    let estAdmin = ref(false)
    let idDgSelectionne = ref(0)
    let joueurSelectionne = ref(null)
    let estAlignementValide = ref(false)
    let operation = ref ("")
    const idTableDraftZone = ref("draftZoneTable")
    const idTableDropZone = ref("dropZoneTable")
    let erreurMouvementJoueur = ref(false)
    let estMouvementJoueurEnCours = ref(false)
    let estTraitementEnCours = ref(false)
    let equipes

    
    onMounted(async () => {      

      titrePage.value = "Repêchage"
      document.title = document.title + " Repêchage "
      estAdmin.value = await estAdministrateur()
      poolers.value = await obtenirPoolers()      
      equipes = await obtenirEquipes()
      const idPoolerCourant = await obtenirIdPoolerCourant()
      idDgSelectionne.value = idPoolerCourant

      joueursLibres.value = await obtenirJoueursLibres()

      nextTick(() => {
        
        definirDatableDraftZone()

        setTimeout(() => {
          $(".collapse.initial").removeClass("initial");         

          const zonesExtensibles = $(".collapse")
          zonesExtensibles.each(function() {
            const elementZoneExtensible = $(this).get(0)
            new bootstrap.Collapse(elementZoneExtensible, {toggle: false})
          })
        })
      });
    });

    const definirDatableDropZone = () => {       
      setTimeout(() => {
        const optionsDT = {
              dom: 'frtip',
              "searching": true,
              "order": [
                  [6, "asc"]
              ]
          }

          definirDataTablesJoueurs(`#${idTableDropZone.value}`, optionsDT)
      }, 0);      
    }

    const definirDatableDraftZone = () => {       

          const optionsDT = {                  
          dom: 'frtip',
          "pageLength": 25,
          "paging": true,
          "info": false,
          "searching": true,
          "order": [
              [6, "desc"]
          ]

        }

        definirDataTablesJoueurs(`#${idTableDraftZone.value}`, optionsDT)               
    }

    const obtenirPoolers = async (force = false) => {     
      return await obtenirDonnees(force, 'poolers', 'PoolersSansCourriel2')  
    }

    const obtenirEquipes = async (force = false) => {     
      return await obtenirDonnees(force, 'equipes', 'Equipes2')  
    }

    const equipeDgSelectionne = computed(() => {           
      if(idDgSelectionne.value !== 0){               
        if($.fn.DataTable.isDataTable(`#${idTableDropZone.value}`)){
          $(`#${idTableDropZone.value}`).DataTable().destroy()
        }
        
        definirDatableDropZone();

        return equipes.find( equipe => equipe.Pooler.Id == idDgSelectionne.value);        
      }
      return null
    })
    
    const nbJoueursEquipeDgSelectionne = computed(() => {     
      if(idDgSelectionne.value !== 0){
        return joueursEquipeDgSelectionne.value.length
      }
      return null
    })

    const joueursEquipeDgSelectionne = computed(() => {     
      if(idDgSelectionne.value !== 0){
        return equipeDgSelectionne.value.JoueursMajeurs.concat(equipeDgSelectionne.value.JoueursMineurs)
      }
      return null
    })

    const obtenirJoueursLibres = async (force = false) => {     
      const joueurs = await obtenirDonnees(force, 'joueursComplet', 'JoueursComplet2')  
      return joueurs.data.filter( joueur => joueur.Proprietaire === 'Libre');
    }
    const dropperJoueur = (joueur) => {     
      operation.value = 'drop'
      reduireZoneExtensible('dropZone')
      joueurSelectionne.value = joueur
      
      repositionnerSurZoneConfirmation()
    }
    const drafterJoueur = (joueur) => {     
      operation.value = 'draft'
      reduireZoneExtensible('draftZone')
      joueurSelectionne.value = joueur
      
      repositionnerSurZoneConfirmation()

    }
    const repositionnerSurZoneConfirmation = () => {
      const yOffset = 40
      nextTick(() => {      
        const element = document.getElementById('zoneConfirmation')
        const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset
        window.scrollTo({top: y, behavior: 'instant'})
      })      


    }
    const reduireZoneExtensible = (idZone) => {
      const zoneExtensible = bootstrap.Collapse.getInstance(document.getElementById(idZone))
      zoneExtensible.hide()
    }

    const peutRepecher = computed(() => {     
      // return joueurSelectionne.value && idDgSelectionne && estAlignementValide.value
      // On permet le repêchage même si alignement invalide (ex. trop de joueurs)
      return joueurSelectionne.value && idDgSelectionne
    })

    const estAlignementValideRepechage = (estValide) => {
      estAlignementValide.value = estValide
    }

    const confirmerMouvement = async() => {
      let donnees

      estMouvementJoueurEnCours.value = true
      fenetreMessageMouvementEnCours.value.afficher()

      if(operation.value === 'drop') {        
        donnees = {"IdJoueur" : joueurSelectionne.value.Id}
      } else {
        donnees = {"NomJoueur" : joueurSelectionne.value.Nom, "PoolerId": idDgSelectionne.value}
      }
      const urlApi = `/api/EffectuerMouvementsJoueursEquipe?type=${operation.value}`
      const resultat = await effectuerAppelServeur(urlApi, donnees)

      if(!resultat){        
        erreurMouvementJoueur.value = true
      } 

      joueurSelectionne.value = null
//      estMouvementJoueurEnCours.value = false
      rafraichirContenu(false)
    }

    const effectuerAppelServeur = async (urlApi, donnees) => {
      // /api/EffectuerMouvementsJoueursEquipe
      const reponse = await fetch(urlApi, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(donnees)
      })

      return reponse.ok

    }
    const obtenirCorpsFenetreMouvementJoueurs = computed(() => {
      if(erreurMouvementJoueur.value){
        return "Une erreur est survenue lors du mouvement de joueurs. Veuillez réessayer."
      }

      if(estMouvementJoueurEnCours.value){
        return `<div class="d-flex flex-column align-items-center"><span class="fas fa-cog fa-spin my-4" style="font-size: 3rem" aria-hidden="true"></span><span>Pas de panique. Les gars font leurs valises...</span></div>`
      } else {
        return "Le mouvement de joueurs est complété."
      }
    })
    const obtenirCorpsFenetreTraitementEnCours = computed(() => {
      if(estTraitementEnCours.value){
        return `<div class="d-flex flex-column align-items-center"><span class="fas fa-cog fa-spin my-4" style="font-size: 3rem" aria-hidden="true"></span><span>Pas de panique. On met à jour les données...</span></div>`
      } else {
        return "Les données sont maintenant à jour."
      }      
    })

    const rafraichirContenu = async (afficherFenetre) => {
      
      if(afficherFenetre){
        estTraitementEnCours.value = true
        fenetreMessageTraitementEnCours.value.afficher()
      }

      if($.fn.DataTable.isDataTable(`#${idTableDraftZone.value}`)){
        $(`#${idTableDraftZone.value}`).DataTable().destroy()
      }

      joueursLibres.value = await obtenirJoueursLibres(true)
      equipes = await obtenirEquipes(true)

      /* Petite twist pour forcer la réévaluation du computed equipeDgSelectionne. Je n'ai pas trouvé mieux pour le moment. */
      const idDg = idDgSelectionne.value
      idDgSelectionne.value = 0
      idDgSelectionne.value = idDg
    
      if(afficherFenetre){
        setTimeout(function(traitementEnCours){
          definirDatableDraftZone()
          traitementEnCours.value = false
        }, 0, estTraitementEnCours);         
      } else {
        setTimeout(function(mouvementEnCours){
          definirDatableDraftZone()
          mouvementEnCours.value = false

        }, 0, estMouvementJoueurEnCours);         
      }
      
    }
    return {
      idTableDraftZone,
      idTableDropZone,
      titrePage, 
      idDgSelectionne,
      equipeDgSelectionne,
      nbJoueursEquipeDgSelectionne,
      joueursEquipeDgSelectionne,
      joueurSelectionne,
      joueursLibres,
      poolers,
      equipes,
      dropperJoueur,
      drafterJoueur,
      peutRepecher,
      estAlignementValide,
      estAlignementValideRepechage,
      operation,
      confirmerMouvement,
      erreurMouvementJoueur,
      obtenirCorpsFenetreMouvementJoueurs,
      obtenirCorpsFenetreTraitementEnCours,
      rafraichirContenu,
      estMouvementJoueurEnCours,
      estTraitementEnCours,
      fenetreMessageMouvementEnCours,
      fenetreMessageTraitementEnCours,
      estAdmin,
      estAdministrateur
    };
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #dg{
    margin-left: 8px;
    display: inline-block;    
  }

  .zone-extensible{
    border-bottom: 2px solid #DBDBDB;
    border-top: 2px solid #DBDBDB;
    margin-bottom: var(--tc-48-40-val);
  }

  .conteneur-datatable {
    background-color: #fff;
    padding: 16px 8px 16px 8px;
  }

  .joueur-selectionne{
    font-weight: 600;
  }

  #zoneConfirmation{
    background-color: #fff;
    border: 3px solid var(--couleur-filet-secondaire);
  }
</style>
