<template>
  <header>
    <div v-if="utilisateurCourant == 0 || !utilisateurCourant.startsWith('000')" class="connexion container">
      <a href="#" @click.prevent="assurerAuthentification()">Me connecter</a>
    </div>

    <nav>        
      <div class="conteneur-nav container d-flex">          
        <div class="id-visuelle d-flex flex-column align-items-center justify-content-center">
          <img src="./assets/img/gardien2.jpg"/>
          <div class="nom-site">
            Poolombier
          </div>
        </div>
        <router-link to="/">
          <span>Classement</span>
        </router-link>
        <router-link to="/Picks">
          <span>Picks</span>
        </router-link>
        <router-link to="/Joueurs">
          <span>Joueurs</span>
        </router-link>
      </div>      
    </nav>
  </header>        
  <main>
    <router-view />  
  </main>          
  <div v-if="afficherInfosDebug" class="infos-debug">Utilisateur : <span>{{utilisateurCourant}}</span></div>
</template>


<script>
import { onMounted, ref } from "vue";
import { base } from './commun/base'
export default {
  name: 'App',
  setup() {       
    const { obtenirIdPoolerCourant, assurerAuthentification } = base()
    let utilisateurCourant = ref(0)
    let afficherInfosDebug = ref(false)

    onMounted(async () => {
      
      utilisateurCourant.value = await obtenirIdPoolerCourant();       
      console.log(`userId = ${utilisateurCourant.value}`);
      afficherInfosDebug.value = true

      if (process.env.NODE_ENV === "development") {
        afficherInfosDebug.value = true
      } 

    });


/*    const obtenirUserId = async () => {
      if (process.env.NODE_ENV == "development") {
        const clientPrincipal = {
          identityProvider: "facebook",
          userDetails: "chriscaux@hotmail.com",
          userId: "c8527ff67af74336b30b02c98a58b9b9",
          userRoles: ["anonymous", "authenticated"]
        }
        console.log("DEV");
        return 1;
      } else {
        const response = await fetch("/.auth/me");
        const payload = await response.json();
        const { clientPrincipal } = payload;

        if (!clientPrincipal) {
          window.location.href = "/login"
        }
        console.log(clientPrincipal);

      }      
    }   */ 

    return {
      utilisateurCourant,
      afficherInfosDebug,
      assurerAuthentification
    }
  }
}

</script>

<style>
header{
  background-color: #000;
}
.connexion{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -24px;
}

.connexion a{
  color: #fff;
  text-decoration: none;
}

.connexion a:hover, .connexion a:focus{
  color: #fff;
  text-decoration: underline;
}

.id-visuelle{
z-index: 99;
    background-color: #000;
    width: 96px;
    height: 86px;
    position: absolute;
    left: 60px;
    top: -24px;
    transform: translate(-50%, 0%);
    border: 1px solid darkgray;
    border-radius: 50%;

    }

.id-visuelle img{
  height: 45px;
}

.nom-site{
  font-weight: 400;
  font-size: 14px;
  font-variant: small-caps;
  color: #fff;
  line-height: 1;
  margin-bottom: 16px;
}

nav{  
  background-color:#002d8d;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  z-index: 98;
  position: relative;
  top: 28px;
  position: relative;
}

.conteneur-nav{
  position: relative;
}

nav a{
  color: #fff;
  text-decoration: none;
  padding: .8rem;
  font-size: var(--tc-16-12-val);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: var(--tc-16-8-val);
}

nav a:last-of-type{
  margin-right: 0;
}

nav a:hover, nav a:focus{
  color: #fff;
  text-decoration: none;
  background-color: #002470;
}

nav a:first-of-type{
  margin-left: 108px;
}

nav .icone {
  font-size: 1.2rem;
}

#app{
   background: url("./assets/img/arena-min-3a.jpg") no-repeat right top fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}
main{
  min-height: 100vh;
  height: 100%;  
  padding: 4rem 0;
}

.infos-debug{
  background-color: #000;
  color: #fff;
  margin-top: 1000px;
}
</style>
