<template>
  <div>
  </div>
</template>

<script>
import { onBeforeMount } from "vue";
import { base } from '../commun/base'

export default {
  name: "Home",
  components: {
  },

  setup() {   
    const { obtenirIdPoolerCourant } = base()

    onBeforeMount(async () => {
      await obtenirIdPoolerCourant(true)
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get('redirectUrl');
      window.location.href = redirectUrl
    });

    return {
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
