<template>
  <div class="container"> 
    <div class="entete-page">
        <h1 class="titre-page">{{titrePage}}</h1>
    </div>
    <div class="contenu-principal-page">
      
      <div class="card mb-48">
        <div class="card-body">
          <div class="d-flex flex-wrap">
            <div class="mb-32 mr-32">
              <label for="dg1">Sélection DG1</label>
              <select id="dg1" class="form-select" v-model="idDgSelectionne1">
                <option value="0" disabled selected>Sélectionnez un DG...</option>
                <option v-for="pooler in poolers" :value="pooler.Id" :key="pooler.Id">
                {{ pooler.Nom }}
                </option>
              </select>
            </div>
            <div class="mb-32">
              <label for="dg2">Sélection DG2</label>
              <select id="dg2" class="form-select" v-model="idDgSelectionne2">
                <option value="0" disabled selected>Sélectionnez un DG...</option>
                <option v-for="pooler in poolers" :value="pooler.Id" :key="pooler.Id">
                {{ pooler.Nom }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>  
      <!-- Équipe DG1 !-->
      <div class="zone-extensible">
        <a class="entete-section" data-bs-toggle="collapse" href="#clubDG1" role="button" aria-expanded="false" aria-controls="clubDG1">
          <span class="titre">
            <span>DG1</span>
          </span>
          <span class="icone-collapse fas"></span>
        </a>          
        <div id="clubDG1" class="collapse">
          <!-- Sommaire équipe DG1 !-->
          <div v-if="idDgSelectionne1 !== '0'" class="sommaire mt-32 mb-32">
            <SommaireAlignement :nbJoueurs="joueursEquipeDg1.length" :nbElc="equipeDg1.NbELC" :contexte="'repechage'" @estAlignementValide="estAlignementValideRepechage"></SommaireAlignement>                  
          </div>

          <div class="conteneur-datatable">
            <Joueurs :id="idTableJoueursDG1" :contexte="estAdmin ? 'echange' : ''" :joueurs="joueursEquipeDg1" @selectionnerJoueur="selectionnerJoueur"></Joueurs>
          </div>

          <div class="d-flex flex-wrap conteneur-choix">
            <template v-for="(choix) in choixEquipeDg1" :key="choix.Id">
              <div class="choix">              
                <input class="form-check-input" type="checkbox" value="" v-model="choix.Selected" :id="'choix'+choix.Id" @change="selectionnerChoix(choix)">
                <label class="form-check-label" :for="'choix'+choix.Id">
                  <span>Ronde {{choix.Round}}</span>
                  <span>{{choix.NomDgInitial}}</span>
                </label>
              </div>                  
            </template>                     
          </div>            

        </div>
      </div>
      <!-- Équipe DG2 !-->
      <div class="zone-extensible">
        <a class="entete-section" data-bs-toggle="collapse" href="#clubDG2" role="button" aria-expanded="false" aria-controls="clubDG2">
          <span class="titre">
            <span>DG2</span>
          </span>
          <span class="icone-collapse fas"></span>
        </a>          
        <div id="clubDG2" class="collapse">
          <!-- Sommaire équipe DG2 !-->
          <div v-if="idDgSelectionne2 !== '0'" class="sommaire mt-32 mb-32">
            <SommaireAlignement :nbJoueurs="joueursEquipeDg2.length" :nbElc="equipeDg2.NbELC" :contexte="'repechage'" @estAlignementValide="estAlignementValideRepechage"></SommaireAlignement>                  
          </div>

          <div class="conteneur-datatable">
            <Joueurs :id="idTableJoueursDG2" :contexte="estAdmin ? 'echange' : ''" :joueurs="joueursEquipeDg2" @selectionnerJoueur="selectionnerJoueur"></Joueurs>
          </div>

          <div class="d-flex flex-wrap conteneur-choix">
            <template v-for="(choix) in choixEquipeDg2" :key="choix.Id">
              <div class="choix">
                <input class="form-check-input" type="checkbox" value="" v-model="choix.Selected" :id="'choix'+choix.Id" @change="selectionnerChoix(choix)">
                <label class="form-check-label" :for="'choix'+choix.Id">
                  <span>Ronde {{choix.Round}}</span>
                  <span>{{choix.NomDgInitial}}</span>
                </label>  
              </div>                
            </template>                     
          </div>            

        </div>
      </div>
      <!-- Confirmation Zone !-->
      <div v-if="estOffreEnCours" class="card mt-32" id="zoneConfirmation">
        <div class="card-header">Confirmer l'échange</div>
        <div class="card-body">
          <div class="offres">              
            <div class="offre-dg mb-32 mr-64">
              <span class="label">Offre DG1</span>            
              <ul>
                <li v-for="joueur in offreJoueursDG1" :key="joueur.Id">
                  <span>{{joueur.Nom}}</span>
                </li>
                <li v-for="choix in offreChoixDG1" :key="choix.Id">
                  <span>Ronde {{choix.Round}} - {{choix.NomDgInitial}}</span>
                </li>
              </ul>
            </div>
            <div class="offre-dg mb-32">
              <span class="label">Offre DG2</span>            
              <ul>
                <li v-for="joueur in offreJoueursDG2" :key="joueur.Id">
                  <span>{{joueur.Nom}}</span>
                </li>
                <li v-for="choix in offreChoixDG2" :key="choix.Id">
                  <span>Ronde {{choix.Round}} - {{choix.NomDgInitial}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="zone-boutons">
            <button type="button" class="btn btn-secondary" :disabled="!estOffreEnCours" @click="annulerOffres">Annuler</button> 
            <button type="button" class="btn btn-primary" :disabled="!estOffreEnCours" @click="confirmerMouvement">Confirmer</button> 
          </div>            
        </div>
      </div>

    </div>
  </div>
  <FenetreMessage ref="fenetreMessageMouvementEnCours" :titre="'Traitement en cours...'" :afficherBoutonFermerEntete=false :afficherBoutonPrincipal=!estMouvementJoueurEnCours :corps="obtenirCorpsFenetreMouvementJoueurs"></FenetreMessage>  
  <FenetreMessage ref="fenetreMessageTraitementEnCours" :titre="'Traitement en cours...'" :afficherBoutonFermerEntete=false :afficherBoutonPrincipal=!estTraitementEnCours :corps="obtenirCorpsFenetreTraitementEnCours"></FenetreMessage>  
</template>

<script>
import { ref, computed, onMounted, nextTick, watch } from "vue"
import { base } from '../commun/base'
import Joueurs from '../components/Joueurs.vue'
import FenetreMessage from '../components/FenetreMessage.vue'
import SommaireAlignement from '../components/Equipe/SommaireAlignement.vue'
import jQuery from "jquery"
import * as bootstrap from 'bootstrap'
const $ = jQuery;
window.$ = $;


export default {
  name: "Echange",
  props: {
  },

  components: {
    Joueurs,
    SommaireAlignement,
    FenetreMessage
  },

  setup() {   


//    const { obtenirDonnees, obtenirTraductionDatatablesFrancais } = base()
    const { obtenirDonnees, definirDataTablesJoueurs, estAdministrateur, obtenirIdPoolerCourant } = base()
    let fenetreMessageMouvementEnCours = ref(null)
    let fenetreMessageTraitementEnCours = ref(null)
    let poolers = ref([])
    let titrePage = ref("")
    let estAdmin = ref(false)
    let idDgSelectionne1 = ref("0")
    let idDgSelectionne2 = ref("0")
    let estAlignementValide = ref(false)
    let operation = ref ("")
    let equipeDg1 = ref([])
    let equipeDg2 = ref([])
    let joueursEquipeDg1 = ref([])
    let choixEquipeDg1 = ref([])
    let joueursEquipeDg2 = ref([])
    let choixEquipeDg2 = ref([])
    let offreJoueursDG1 = ref([])
    let offreJoueursDG2 = ref([])
    let offreChoixDG1 = ref([])
    let offreChoixDG2 = ref([])
    const idTableJoueursDG1 = "JoueursDG1Table"
    const idTableJoueursDG2 = "JoueursDG2Table"
    let erreurMouvementJoueur = ref(false)
    let estMouvementJoueurEnCours = ref(false)
    let estTraitementEnCours = ref(false)
    let equipes = []
    let choixRepechage = []    
    
    onMounted(async () => {      

      titrePage.value = "Échange"
      document.title = document.title + " Échange "
      estAdmin.value = await estAdministrateur()
      poolers.value = await obtenirPoolers()      
      equipes = await obtenirEquipes()
      choixRepechage = await obtenirChoix()  
      const idPoolerCourant = await obtenirIdPoolerCourant()
      idDgSelectionne1.value = idPoolerCourant

      nextTick(() => {
        
        setTimeout(() => {
          $(".collapse.initial").removeClass("initial");         

          const zonesExtensibles = $(".collapse")
          zonesExtensibles.each(function() {
            const elementZoneExtensible = $(this).get(0)
            new bootstrap.Collapse(elementZoneExtensible, {toggle: false})
          })
        })
      });
    });

    const definirDatableJoueursDG1 = () => {       
        const optionsDT = {
            dom: 'frtip',
            "searching": true,
            "order": [
                [6, "asc"]
            ]
        }

        definirDataTablesJoueurs(`#${idTableJoueursDG1}`, optionsDT)      
    }

    const definirDatableJoueursDG2 = () => {       
        const optionsDT = {
            dom: 'frtip',
            "searching": true,
            "order": [
                [6, "asc"]
            ]
        }

        definirDataTablesJoueurs(`#${idTableJoueursDG2}`, optionsDT)      
    }

    const obtenirPoolers = async (force = false) => {     
      return await obtenirDonnees(force, 'poolers', 'PoolersSansCourriel2')  
    }

    const obtenirEquipes = async (force = false) => {     
      return await obtenirDonnees(force, 'equipes', 'Equipes2')  
    }

    const obtenirChoix = async (force = false) => {           
      return await obtenirDonnees(force, 'choix', 'ChoixRepechage2')  
    }

    const estOffreEnCours = computed(() => {
      return offreJoueursDG1.value.length || offreJoueursDG2.value.length || offreChoixDG1.value.length || offreChoixDG2.value.length
    })

    watch(idDgSelectionne1, (idDg) => {
      chargerEquipeDG1(idDg)
    })

    watch(idDgSelectionne2, (idDg) => {
        if(idDg !== "0"){        
          chargerEquipeDG2(idDg)       
        }
      }
    )

    const chargerEquipeDG1 = (idDg) => {
          offreJoueursDG1.value = []
          offreChoixDG1.value = []

          equipeDg1.value = equipes.find( equipe => equipe.Pooler.Id == idDg)
          joueursEquipeDg1.value = equipeDg1.value.JoueursMajeurs.concat(equipeDg1.value.JoueursMineurs)
          choixEquipeDg1.value = choixRepechage.filter(choix => choix.IdDgActuel === idDg) 

          if($.fn.DataTable.isDataTable(`#${idTableJoueursDG1}`)){
            $(`#${idTableJoueursDG1}`).DataTable().destroy()
          }
  
          setTimeout(() => {
            definirDatableJoueursDG1()          
          }, 0);         
    }

    const chargerEquipeDG2 = (idDg) => {
          offreJoueursDG2.value = []
          offreChoixDG2.value = []

          equipeDg2.value = equipes.find( equipe => equipe.Pooler.Id == idDg)
          joueursEquipeDg2.value = equipeDg2.value.JoueursMajeurs.concat(equipeDg2.value.JoueursMineurs)
          choixEquipeDg2.value = choixRepechage.filter(choix => choix.IdDgActuel === idDg) 

          if($.fn.DataTable.isDataTable(`#${idTableJoueursDG2}`)){
            $(`#${idTableJoueursDG2}`).DataTable().destroy()
          }
  
          setTimeout(() => {
            definirDatableJoueursDG2()          
          }, 0);         
    }

    const selectionnerJoueur = () => {     
      offreJoueursDG1.value = joueursEquipeDg1.value.filter(joueur => joueur.Selected);
      offreJoueursDG2.value = joueursEquipeDg2.value.filter(joueur => joueur.Selected);
    }

    const selectionnerChoix = () => {     
      offreChoixDG1.value = choixEquipeDg1.value.filter(choix => choix.Selected);
      offreChoixDG2.value = choixEquipeDg2.value.filter(choix => choix.Selected);
    }

    const annulerOffres = () => {
      joueursEquipeDg1.value.forEach(joueur => joueur.Selected = false)
      joueursEquipeDg2.value.forEach(joueur => joueur.Selected = false)
      choixEquipeDg1.value.forEach(choix => choix.Selected = false)
      choixEquipeDg2.value.forEach(choix => choix.Selected = false)

      offreJoueursDG1.value = [];
      offreJoueursDG2.value = [];
      offreChoixDG1.value = [];
      offreChoixDG2.value = [];
    }

    const estAlignementValideRepechage = (estValide) => {
      estAlignementValide.value = estValide
    }

    const confirmerMouvement = async() => {

      estMouvementJoueurEnCours.value = true
      fenetreMessageMouvementEnCours.value.afficher()

      const urlApi = `/api/EffectuerMouvementsJoueursEquipe?type=echange`
      const resultat = await effectuerAppelServeur(urlApi, obtenirDonneesEchange())

      if(!resultat){        
        erreurMouvementJoueur.value = true
      } 

      estMouvementJoueurEnCours.value = false
      rafraichirContenu(false)
    }

    const obtenirDonneesEchange = () => {
      const echange = []
      offreJoueursDG1.value.forEach((j) => {
        const joueur = {}
        joueur.PoolerId = idDgSelectionne2.value
        joueur.IdChoix = ""
        joueur.IdJoueur = j.Id
        echange.push(joueur)
      }) 

      offreJoueursDG2.value.forEach((j) => {
        const joueur = {}
        joueur.PoolerId = idDgSelectionne1.value
        joueur.IdChoix = ""
        joueur.IdJoueur = j.Id

        echange.push(joueur)
      }) 

      offreChoixDG1.value.forEach((c) => {
        const choix = {}
        choix.PoolerId = idDgSelectionne2.value
        choix.IdChoix = c.Id
        choix.IdJoueur = ""

        echange.push(choix)
      }) 

      offreChoixDG2.value.forEach((c) => {
        const choix = {}
        choix.PoolerId = idDgSelectionne1.value
        choix.IdChoix = c.Id
        choix.IdJoueur = ""
        echange.push(choix)
      }) 

      return echange
    }

    const effectuerAppelServeur = async (urlApi, donnees) => {
      // /api/EffectuerMouvementsJoueursEquipe
      const reponse = await fetch(urlApi, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(donnees)
      })

      return reponse.ok

    }
    const obtenirCorpsFenetreMouvementJoueurs = computed(() => {
      if(erreurMouvementJoueur.value){
        return "Une erreur est survenue lors du mouvement de joueurs. Veuillez réessayer."
      }

      if(estMouvementJoueurEnCours.value){
        return `<div class="d-flex flex-column align-items-center"><span class="fas fa-cog fa-spin my-4" style="font-size: 3rem" aria-hidden="true"></span><span>Pas de panique. Les gars font leurs valises...</span></div>`
      } else {
        return "Le trade est complété."
      }
    })
    const obtenirCorpsFenetreTraitementEnCours = computed(() => {
      if(estTraitementEnCours.value){
        return `<div class="d-flex flex-column align-items-center"><span class="fas fa-cog fa-spin my-4" style="font-size: 3rem" aria-hidden="true"></span><span>Pas de panique. On met à jour les données...</span></div>`
      } else {
        return "Les données sont maintenant à jour."
      }      
    })

    const rafraichirContenu = async (afficherFenetre) => {
      
      if(afficherFenetre){
        estTraitementEnCours.value = true
        fenetreMessageTraitementEnCours.value.afficher()
      }

      if($.fn.DataTable.isDataTable(`#${idTableJoueursDG1}`)){
        $(`#${idTableJoueursDG1}`).DataTable().destroy()
      }

      if($.fn.DataTable.isDataTable(`#${idTableJoueursDG2}`)){
        $(`#${idTableJoueursDG2}`).DataTable().destroy()
      }

      equipes = await obtenirEquipes(true)
      choixRepechage = await obtenirChoix(true)
      await obtenirDonnees(true, 'joueursComplet', 'JoueursComplet2') 

      chargerEquipeDG1(idDgSelectionne1.value)
      chargerEquipeDG2(idDgSelectionne2.value)
      
      if(afficherFenetre){
        setTimeout(function(traitementEnCours){
          traitementEnCours.value = false
          window.scrollTo(0,0)
        }, 0, estTraitementEnCours);         
      } else {
        setTimeout(function(mouvementEnCours){
          mouvementEnCours.value = false
          window.scrollTo(0,0)
        }, 0, estMouvementJoueurEnCours);         
      }      
    }
    return {
      idTableJoueursDG1,
      idTableJoueursDG2,
      titrePage, 
      idDgSelectionne1,
      idDgSelectionne2,
      equipeDg1,
      equipeDg2,
      joueursEquipeDg1,
      choixEquipeDg1,
      joueursEquipeDg2,
      choixEquipeDg2,
      poolers,
      equipes,
      choixRepechage,
      selectionnerJoueur,
      selectionnerChoix,
      offreJoueursDG1,
      offreJoueursDG2,
      offreChoixDG1,
      offreChoixDG2,
      estOffreEnCours,
      annulerOffres,
      estAlignementValide,
      estAlignementValideRepechage,
      operation,
      confirmerMouvement,
      erreurMouvementJoueur,
      obtenirCorpsFenetreMouvementJoueurs,
      obtenirCorpsFenetreTraitementEnCours,
      rafraichirContenu,
      estMouvementJoueurEnCours,
      estTraitementEnCours,
      fenetreMessageMouvementEnCours,
      fenetreMessageTraitementEnCours,
      estAdmin,
      estAdministrateur
    };
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #dg{
    margin-left: 8px;
    display: inline-block;    
  }

  .zone-extensible{
    border-bottom: 2px solid #DBDBDB;
    border-top: 2px solid #DBDBDB;
    margin-bottom: var(--tc-48-40-val);
  }

  .conteneur-datatable {
    background-color: #fff;
    padding: 16px 8px 16px 8px;
  }

  #zoneConfirmation{
    background-color: #fff;
    border: 3px solid var(--couleur-filet-secondaire);
  }

  .card-header {
    font-size: 18px;
    font-weight: 600;
  }

  .table-joueurs .form-check-input, .choix .form-check-input{
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0;
  }

  .choix {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    border: 1px solid var(--couleur-filet-secondaire);
    margin: 0 32px 32px 0;
    background-color: #fff;
  }

  .choix label{
    margin-top: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .offres{
    display: flex;
    flex-wrap: wrap;
  }

  .offres ul {
    margin: 0;
  }

  /* redéfinitions */
  .zone-boutons{
    margin: 0;
  }

  .conteneur-choix{
    margin-top: 32px;
  }
</style>
