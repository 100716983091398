<template>
  <div class="row">
    <router-link :to="{ name: 'Equipe', params: { idPooler: equipe.Pooler.Id }}" class="equipe col-12" :class="{'pooler-courant': estEquipePoolerCourant}">
      <span class="gauche d-flex align-items-center">
        <span class="badge position">
          <span class="nombre">{{position}}</span>
        </span>
        <span class="dg">
          <span class="nom">{{ equipe.Pooler.Nom }}</span>
          <span class="nickname">({{ equipe.Pooler.Nickname }})</span>
          <span class="statut" :class="{invalide: !equipe.AlignementValide || !equipe.MasseSalarialeValide}"></span>
        </span>
      </span>
      <span class="d-flex align-items-center">
        <span class="pts hier d-flex flex-column">
          <span class="nombre">{{equipe.PointsPoolHier}}</span>
          <span class="texte">Hier</span>
        </span>
        <span class="pts d-flex flex-column">
          <span class="nombre">{{equipe.PointsPool}}</span>
          <span class="texte">Pts</span>
        </span>
        <span class="icone fas fa-chevron-right" aria-hidden="true"></span>
      </span>                
    </router-link>    
  </div>
</template>

<script>
export default {
  name: "EquipeSommaire",
  props: {
    position: Number,
    equipe: Object,
    estEquipePoolerCourant: Boolean
  },
  setup() {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a.equipe{
  padding-top: .75rem;
  padding-bottom: .75rem;
  border-bottom: 1px solid var(--couleur-filet-secondaire);
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
}

a.equipe.pooler-courant{
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  color: #000;
  background-color: var(--couleur-fond-highlight);
}

a.equipe.pooler-courant .pts .texte{
  color: var(--couleur-texte-base);
}

a.equipe.pooler-courant .badge{
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
}

 a.equipe.pooler-courant .icone{
  color: #000;
}

a.equipe:hover, a.equipe:focus{
  background-color: #ecf2f7;
}

.position{
  margin-right: .75rem;
  border: 1px solid #BFBFBF;
  background-color: #e4e4e4;
  color: inherit;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  min-height: 28px;
  min-width: 28px;
}

a.equipe:hover .dg .nom, a.equipe:focus .dg .nom{
  text-decoration: underline;
}

.nom{
  font-weight: 500;
  margin-right: .25rem;
}

.nickname{
  font-size: .8rem;
}

.statut{
  font-family: "Font Awesome 5 Free";
  margin-left: .25rem;
}

.statut.invalide:before{
  content: "\f06a";
  color: var(--couleur-erreur);
}

.pts{
  width: 100%;
  min-width: 32px;
  line-height :1.2;
  margin: 0 var(--tc-16-8-val);
}

.pts .nombre{
  font-size: .9rem;
  font-weight: 700;
}

.pts.hier .nombre{
  font-weight: 400;
}
.pts .texte{
  font-size: .8rem;
  color: var(--couleur-texte-secondaire);
}

.icone{
  color: #929292;
}
</style>
