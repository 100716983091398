<template>
  <div class="modal fade" :id="idMessage" data-bs-keyboard="false" tabindex="-1" :aria-labelledby="idTitre" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" :id="idTitre">{{titre}}</h2>
          <button v-if="afficherBoutonFermerEntete" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fermer" data-raison-fermeture="fermeture"></button>
        </div>
        <div class="modal-body" v-html="corps">
        </div>
        <div class="modal-footer" v-if="afficherBoutonSecondaire || afficherBoutonPrincipal">
          <button v-if="afficherBoutonSecondaire" button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="clickBoutonSecondaire">{{texteBoutonSecondaire}}</button>
          <button v-if="afficherBoutonPrincipal" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="clickBoutonPrincipal">{{texteBoutonPrincipal}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { base } from '../commun/base'
import jQuery from "jquery";
import * as bootstrap from 'bootstrap'
const $ = jQuery;
window.$ = $;


    function obtenirElementsFocusables(element) {      
      return element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
    }
    /**
     * Conserve le focus dans l'objet javascript reçu en paramètre.
     * @param {object} element Objet javascript dans lequel on veut conserver le focus.
    */
    function conserverFocusElement (element) {
      const elementsFocusables = obtenirElementsFocusables(element);
      const premierElementFocusable = elementsFocusables[0];
      const dernierElementFocusable = elementsFocusables[elementsFocusables.length - 1];
      var CODE_TAB = 9;

      element.addEventListener('keydown', function (e) {
        var toucheTabAppuyee = (e.key === 'Tab' || e.keyCode === CODE_TAB);

        if (!toucheTabAppuyee) {
          return;
        }

        if (e.shiftKey) /* SHIFT + TAB */ {
          if (document.activeElement === premierElementFocusable) {
            if ($(dernierElementFocusable).is(':visible')) {
              dernierElementFocusable.focus();
              e.preventDefault();
            }
          }
        } else /* TAB */ {
          if (document.activeElement === dernierElementFocusable) {
            if ($(premierElementFocusable).is(':visible')) {
              premierElementFocusable.focus();
              e.preventDefault();
            }
          }
        }
      });
    }
  

export default {
  name: "FenetreMessage",
  props: {
    type: String,
    titre: String,
    corps: String,
    texteBoutonPrincipal: {type: String, default: "Ok"},
    texteBoutonSecondaire: {type: String, default: "Annuler"},
    afficherBoutonPrincipal: {type: Boolean, default: true},
    afficherBoutonSecondaire: Boolean,
    afficherBoutonFermerEntete: {type: Boolean, default: true}
  },
  setup(props, { emit }) {
    const { genererId } = base()
    const idMessage = ref(genererId())
    const idTitre = ref(genererId())
    let fenetreMessage = null;
    let fenetreMessageBs = null;

    onMounted(async () => {    

      fenetreMessage = document.getElementById(idMessage.value)
      fenetreMessageBs = new bootstrap.Modal(fenetreMessage, {
        backdrop: "static", 
        keyboard: false
      });

      conserverFocusElement(fenetreMessage);
      definirEvenements();
    })

    //parametres.type && parametres.type !== "information" && parametres.type !== "erreur"    
    const definirEvenements = () => {      
        fenetreMessage.addEventListener('hidden.bs.modal', function () {
        const raisonFermerture = "À définir";
        emit('fenetreModaleFermee', raisonFermerture);
      })
    };

    const clickBoutonPrincipal = () =>{
      emit('clickBoutonPrincipal');
    }

    const clickBoutonSecondaire = () =>{
      emit('clickBoutonSecondaire');
    }

    const afficher = () => {
      fenetreMessageBs.show();
    }

    const masquer = () => {
      fenetreMessageBs.hide();
    }

    return {
      afficher,
      masquer,
      clickBoutonPrincipal,
      clickBoutonSecondaire,
      idMessage,
      idTitre
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
