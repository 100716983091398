<template>
  <div class="container">
    <div class="entete-page">
        <h1 class="titre-page">Classement général</h1>
    </div>
    <div class="contenu-principal-page">
      <div class="equipes">
        <EquipeSommaire v-for="(equipe, index) in equipes" :key="equipe.Pooler.Id" :equipe="equipe" :idPooler=equipe.Pooler.Id :position=index+1 :estEquipePoolerCourant="idPoolerCourant == equipe.Pooler.Id" />
      </div>    
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { base } from '../commun/base'
import EquipeSommaire from '../components/Equipe/EquipeSommaire.vue'

export default {
  name: "Home",
  components: {
    EquipeSommaire
  },

  setup() {   
    let equipes = ref([])
    let idPoolerCourant = ref(0)
    const { obtenirDonnees, obtenirIdPoolerCourant } = base()

    onMounted(async () => {
      equipes.value = await obtenirDonnees(false, 'equipes', 'Equipes2')
      idPoolerCourant.value = await obtenirIdPoolerCourant()
    });

    return {
      equipes,
      idPoolerCourant
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .equipes >>> .row:first-of-type{
    border-top: 1px solid var(--couleur-filet-secondaire);
  }

  .contenu-principal-page{
    padding-top:0;
    padding-bottom: 0;
  }
</style>
