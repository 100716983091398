<template>
  <div class="container"> 
    <div class="entete-page">
        <h1 class="titre-page">{{titrePage}}</h1>
    </div>
    <div class="contenu-principal-page">
      <div v-if="!estObjetVide(equipe)">
        <div id="Actions" v-if="(estEquipePoolerCourant || estAdmin) && !estMouvementsActifs" class="d-flex justify-content-between">
          <button type="button" @click="activerDesactiverMouvementPersonnel" class="btn btn-secondary">Mouvements<span aria-hidden="true" class="fas fa-exchange-alt fa-rotate-90 ms-2"></span></button>
          <button type="button" v-if="estAdmin" class="btn btn-secondary" @click="activerEchange()">Échange</button>             
          <button type="button" class="btn btn-secondary" @click="rafraichirContenu()">Rafraîchir</button>             
        </div>                
        <div  class="joueurs-majeurs">
          <a class="entete-section" data-bs-toggle="collapse" href="#joueursMajeurs1" role="button" aria-expanded="true" aria-controls="joueursMajeurs1">
            <span class="titre">
              <span>Grand club</span><span aria-hidden="true" class="icone fas fa-plane"></span>
            </span>
            <span class="icone-collapse fas"></span>
          </a>          
          <div id="joueursMajeurs1" class="collapse show initial">
          <table id="joueursMajeurs" class="stripe hover row-border" style="width:100%">
            <thead>
                <tr>                
                    <th>Nom</th>
                    <th>Pos</th>
                    <th>Ville</th>
                    <th>Age</th>
                    <th>PJ</th>
                    <th>1</th>                    
                    <th>Pts</th>
                    <th>24-25</th>
                    <th>25-26</th>                
                    <th>26-27</th>
                    <th>27-28</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(joueur, index) in equipe.JoueursMajeurs" :key="joueur.Nom" :joueur="joueur" :indexJoueur="index" :class="{selected: joueur.Selected}" >
                    <td>
                      <span class="d-flex align-items-center">
                        <button v-if="estMouvementsActifs" type="button" class="btn btn-sm mouvement" :class="[joueur.Selected ? 'btn-secondary' : 'btn-outline-secondary']" @click="effectuerMouvementJoueur(joueur, false)">
                          <span aria-hidden="true" class="fas fa-arrow-down"></span>
                        </button>
                        <span>
                          <span>{{joueur.Nom}}</span>
                        </span>
                        <a v-if="obtenirStatut(joueur) != '' || joueur.Blessure != null || joueur.ELC" tabindex="0" class="statut" role="button" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="focus" data-bs-html="true" :title="joueur.Nom" :data-bs-content="obtenirTexteStatut(joueur)">
                          <span v-if="obtenirStatut(joueur) != ''" :class="[obtenirStatut(joueur), 'statut']"></span>
                          <span v-if="joueur.Blessure != null" class="statut blesse"></span>
                          <span v-if="joueur.ELC" class="statut elc">(ELC)</span>
                        </a>
                      </span>
                    </td>
                    <td>{{joueur.Pos}}</td>
                    <td>{{joueur.Eqp}}</td>
                    <td>{{joueur.Age}}</td>
                    <td>{{joueur.GP}}</td>
                    <td>{{joueur.PHier}}</td>
                    <td class="pts">{{joueur.P}}</td>
                    <td>{{joueur.Sal}}</td>
                    <td>{{joueur.Sal1}}</td>
                    <td>{{joueur.Sal2}}</td>
                    <td>{{joueur.Sal3}}</td>
                    <td></td>                
                </tr>
              </tbody>
          </table>  
          </div>
        </div>
        <div v-if="estMouvementsActifs" class="mouvements-joueurs sommaire">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Mouvements de joueurs <span class="text-base">({{deltaPts}} points</span>)</div>
              <div class="sommaire-mouvements d-flex flex-wrap">
                <div class="bloc-infos">
                  <span class="d-block libelle">Vers majeurs</span>
                  <ul>
                    <li v-for="(joueur, index) in mouvementsJoueurs.VersMajeurs" :key="joueur.Nom" :joueur="joueur" :indexJoueur="index">
                      <span>{{joueur.Nom}}</span>
                    </li>
                  </ul>
                </div>
                <div class="bloc-infos">
                  <span class="d-block libelle">Vers mineurs</span>
                  <ul>
                    <li v-for="(joueur, index) in mouvementsJoueurs.VersMineurs" :key="joueur.Nom" :joueur="joueur" :indexJoueur="index">
                      <span>{{joueur.Nom}}</span>
                    </li>
                  </ul>
                </div>
                <div class="bloc-infos">
                  <SommaireSalaire :plafond="equipe.PlafondSalarial" :masse="nouvelleMasseSalariale" :disponible="nouvelleMasseDisponible" @estMouvementMasseValide="estMouvementMasseValide" ></SommaireSalaire>
                </div>
                <div class="bloc-infos">
                  <SommaireAlignement :nbAttaquants="nouveauNbAttaquants" :nbDefenseurs="nouveauNbDefenseurs" :nbGardiens="nouveauNbGardiens" :nbElc="equipe.NbELC" :contexte="'mouvement-joueurs'" @estAlignementValide="estAlignementValide"></SommaireAlignement>                  
                </div>
              </div>
              <div class="zone-boutons">
                <button type="button" class="btn btn-outline-secondary me-4" @click="activerDesactiverMouvementPersonnel">Annuler</button>
                <button type="button" class="btn btn-primary" :disabled="!peutConfirmerMouvement" @click="confirmerMouvements">Confirmer</button>
              </div>    
            </div>
          </div>
        </div>

        <div  class="joueurs-mineurs">
          <a class="entete-section" data-bs-toggle="collapse"  href="#joueursMineurs1" role="button" aria-expanded="false" aria-controls="joueursMineurs1">
            <span class="titre">
              <span>Club école</span><span aria-hidden="true" class="icone fas fa-bus"></span>
            </span>
            <span class="icone-collapse fas"></span>
          </a>          
          <div id="joueursMineurs1" class="collapse">
          <table id="joueursMineurs" class="stripe hover row-border" style="width:100%">
            <thead>
                <tr>                
                    <th>Nom</th>
                    <th>Pos</th>
                    <th>Ville</th>
                    <th>Age</th>
                    <th>PJ</th>
                    <th>1</th>
                    <th>Pts</th>
                    <th>24-25</th>
                    <th>25-26</th>                
                    <th>26-27</th>
                    <th>27-28</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(joueur, index) in equipe.JoueursMineurs" :key="joueur.Nom" :joueur="joueur" :indexJoueur="index" :class="{selected: joueur.Selected}" >
                    <td>
                      <span class="d-flex align-items-center">
                        <button v-if="estMouvementsActifs" type="button" class="btn btn-sm mouvement" :class="[joueur.Selected ? 'btn-secondary' : 'btn-outline-secondary']" @click="effectuerMouvementJoueur(joueur, true)">
                          <span aria-hidden="true" class="fas fa-arrow-up"></span>
                        </button>
                        <span>
                          <span>{{joueur.Nom}}</span>
                        </span>
                        <a v-if="obtenirStatut(joueur) != '' || joueur.Blessure != null || joueur.ELC" tabindex="0" class="statut" role="button" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="focus" data-bs-html="true" :title="joueur.Nom" :data-bs-content="obtenirTexteStatut(joueur)">
                          <span v-if="obtenirStatut(joueur) != ''" :class="[obtenirStatut(joueur), 'statut']"></span>
                          <span v-if="joueur.Blessure != null" class="statut blesse"></span>
                          <span v-if="joueur.ELC" class="statut elc">(ELC)</span>
                        </a>
                      </span>
                    </td>
                    <td>{{joueur.Pos}}</td>
                    <td>{{joueur.Eqp}}</td>
                    <td>{{joueur.Age}}</td>
                    <td>{{joueur.GP}}</td>
                    <td>{{joueur.PHier}}</td>
                    <td class="pts">{{joueur.P}}</td>
                    <td>{{joueur.Sal}}</td>
                    <td>{{joueur.Sal1}}</td>
                    <td>{{joueur.Sal2}}</td>
                    <td>{{joueur.Sal3}}</td>
                    <td></td>                
                </tr>
              </tbody>
          </table>  
          </div>
        </div>

        <div class="choix-repechage">
          <a class="entete-section" data-bs-toggle="collapse"  href="#choixRepechage" role="button" aria-expanded="false" aria-controls="choixRepechage">
            <span class="titre">
              <span>Choix repêchage 2025</span><span aria-hidden="true" class="icone fas fa-clipboard-list"></span>
            </span>
            <span class="icone-collapse fas"></span>
          </a>          
          <div id="choixRepechage" class="collapse">
            <div class="d-flex flex-wrap conteneur-choix">
              <ChoixRepechage :idPooler="equipe.Pooler.Id"></ChoixRepechage>            
            </div>            
          </div>
        </div>
        <div v-if="!estMouvementsActifs" class="sommaire d-flex flex-wrap">
          <SommaireSalaire :plafond="equipe.PlafondSalarial" :masse="equipe.MasseSalariale" :estValide="equipe.MasseSalarialeValide" :disponible="equipe.MasseDisponible"></SommaireSalaire>
          <SommaireAlignement :nbAttaquants="equipe.NbAttaquants" :nbDefenseurs="equipe.NbDefenseurs" :nbGardiens="equipe.NbGardiens" :nbElc="equipe.NbELC" :estValide="equipe.AlignementValide"></SommaireAlignement>
        </div>
      </div>    
    </div>
  </div>
  <FenetreMessage ref="fenetreMessage" :titre="'Traitement en cours...'" :afficherBoutonFermerEntete=false :afficherBoutonPrincipal=!estMouvementJoueurEnCours :corps=obtenirCorpsFenetreMouvementJoueurs() @clickBoutonPrincipal="rafraichirContenu"></FenetreMessage>
</template>

<script>
import { ref, onMounted, nextTick, computed } from "vue";
import { base } from '../commun/base'
import ChoixRepechage from '../components/ChoixRepechage.vue';
import SommaireSalaire from '../components/Equipe/SommaireSalaire.vue';
import SommaireAlignement from '../components/Equipe/SommaireAlignement.vue';
import FenetreMessage from '../components/FenetreMessage.vue';
import jQuery from "jquery";
import * as bootstrap from 'bootstrap'
const $ = jQuery;
window.$ = $;


export default {
  name: "Equipe",
  props: {
    idPooler: String
  },

  components: {
    ChoixRepechage,
    SommaireSalaire,
    SommaireAlignement,
    FenetreMessage
  },

  setup(props) {   

    const { obtenirDonnees, obtenirIdPoolerCourant, estAdministrateur, assurerAuthentification } = base()
    const fenetreMessage = ref(null)
    let equipeMouvements = {}
    let estAdmin = ref(false)
    let estEquipePoolerCourant = ref(false)
    let nouvelleMasseSalariale = ref(0)
    let nouvelleMasseDisponible = ref(0)
    let nouveauNbAttaquants = ref(0)
    let nouveauNbDefenseurs = ref(0)
    let nouveauNbGardiens = ref(0)  
    let deltaPts = ref("")  
    let equipe = ref({})    
    let titrePage = ref("")
    let mouvementsJoueurs = ref({VersMajeurs: [], VersMineurs: []})
    let estMouvementsActifs = ref(false)
    let estMouvementsAlignementValide = ref(true)
    let estMouvementsMasseSalarialeValide = ref(true)
    let estMasseSalarialeValide = ref(false)
    let estMouvementJoueurEnCours = ref(false)
    let erreurMouvementJoueur = ref(false)
    

    onMounted(async () => {    
      estAdmin.value = await estAdministrateur()  
      equipe.value = await obtenirEquipe()

      titrePage.value = "Équipe " + equipe.value.Pooler.Nom;
      document.title = document.title + " Équipe de " + equipe.value.Pooler.Nom;      

      const idPoolerCourant = await obtenirIdPoolerCourant()
      estEquipePoolerCourant.value = idPoolerCourant == equipe.value.Pooler.Id

      nextTick(() => {

        definirDataTables("#joueursMajeurs");
        definirDataTables("#joueursMineurs");
        
        setTimeout(() => {
          $(".collapse.initial").removeClass("initial");         

        const zonesExtensibles = $(".collapse")
        zonesExtensibles.each(function() {
          const elementZoneExtensible = $(this).get(0)


            new bootstrap.Collapse(elementZoneExtensible, {toggle: false});
//          const zoneExtensible = bootstrap.Collapse.getInstance(elementZoneExtensible)
//            zoneExtensible.hide()
/*          if(zoneExtensible){
            zoneExtensible.show()
          } else {
            new bootstrap.Collapse(elementZoneExtensible, {
            });
          }*/
        })

          //Activer les popovers (statut)
          var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))          
          popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
          });
        });
      });
    });

    const obtenirEquipe = async (force = false) => {     
      const equipes = await obtenirDonnees(force, 'equipes', 'Equipes2')  
      return equipes.find( equipe => equipe.Pooler.Id == props.idPooler);
    }
    const estObjetVide = (obj) => {
      return Object.entries(obj).length === 0 && obj.constructor === Object;
    }

    const obtenirStatut = (joueur) => {
      const mineurs = ["Nhl Non-Roster", "Minor", "Junior", "Loan"];      

      //Status mineurs et taxi squad
      if(mineurs.indexOf(joueur.Statut) >= 1){
        return "mineurs";
      } else if (joueur.Statut == "Taxi Squad"){
        return "taxi";
      }

      return "";
    }

    const obtenirTexteStatut = (joueur) => {

      let statut = obtenirStatut(joueur);
      let texte = "";

      if(statut == "mineurs"){
        texte = "<p>Le joueur est suspendu, dans les ligues mineures ou sans contrat.</p>"
      } else if(statut == "taxi"){
        texte += "<p>Le joueur est sur le taxi squad.</p>"
      }

      if(joueur.Blessure != null){
        var blessure = joueur.Blessure;
        //texte += "<p>Le joueur est blessé.<br/>" + blessure.Description + " - " + blessure.Retour + "<br/>Retour prévu : " + blessure.DateRetour + "</p>";
        texte += "<p>Le joueur est blessé.<br/>" + blessure.Description  + "</p>";
      }

      if(joueur.ELC){
        texte += "<p>Le joueur a un contrat d'entrée (ELC).</p>";
      }

      return texte;
    }

    const peutConfirmerMouvement = computed(() => {   
      return true;       
      //return estMouvementsAlignementValide.value && estMouvementsMasseSalarialeValide.value && (mouvementsJoueurs.value.VersMajeurs.length > 0 || mouvementsJoueurs.value.VersMineurs.length > 0);
    })

    const confirmerMouvements = async () => {

      estMouvementJoueurEnCours.value = true
      fenetreMessage.value.afficher()

      let mouvements = []
      mouvementsJoueurs.value.VersMajeurs.forEach(joueur => {
        mouvements.push({IdJoueur: joueur.Id, Mineurs: false})
      })

      mouvementsJoueurs.value.VersMineurs.forEach(joueur => {
        mouvements.push({IdJoueur: joueur.Id, Mineurs: true})
      })

      const reponse = await fetch("/api/EffectuerMouvementsJoueursEquipe?type=MoveMineursMajeurs", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(mouvements)
      });

      //console.log(reponse)

      if(reponse.ok){
          await reponse.json()
      } else {
        console.log(reponse)
        erreurMouvementJoueur.value = true
      }       

      estMouvementJoueurEnCours.value = false
    }

    const rafraichirContenu = async () => {
      await obtenirEquipe(true)
      history.scrollRestoration = "manual"
//      window.scrollTo(0,0)
      window.location.reload()
    }

    const activerEchange = async () => { 
      const idPooler = await assurerAuthentification()
      if(idPooler === '0' ){
        return
      }
      window.location.href = "/echange"
    }

    const activerDesactiverMouvementPersonnel = async () =>{

      const idPooler = await assurerAuthentification()
      if(idPooler === '0' ){
        return
      }

      estMouvementsActifs.value = !estMouvementsActifs.value;
      
      var tables = $(".dataTable").DataTable();

      tables.columns.adjust().draw();

      if(estMouvementsActifs.value){
        
        const zonesExtensiblesReduites = $('.collapse').not(".show");

        zonesExtensiblesReduites.each(function() {
          const elementZoneExtensible = $(this).get(0)
          const zoneExtensible = bootstrap.Collapse.getInstance(elementZoneExtensible)
          
          if(zoneExtensible){
            zoneExtensible.show()
          } else {
            new bootstrap.Collapse(elementZoneExtensible, {
              show: true
            });
          }
        })

        afficherDeltaMouvement();
      } else {
        
        reInitialiserMouvementsJoueurs();
      }
    }  

    const reInitialiserMouvementsJoueurs = () => {
        mouvementsJoueurs.value.VersMajeurs = [];
        mouvementsJoueurs.value.VersMineurs = [];
        
        equipe.value.JoueursMajeurs.forEach(joueur => {
          joueur.Selected = false;
        });
        
        equipe.value.JoueursMineurs.forEach(joueur => {
          joueur.Selected = false;
        });
    }


    const effectuerMouvementJoueur = (joueur, monter) => {

      joueur["Selected"] = !joueur.Selected;

      if(monter){
        
        const indexeJoueur = mouvementsJoueurs.value.VersMajeurs.findIndex(j => j.Nom == joueur.Nom);
        
        if(joueur.Selected){          
          if(indexeJoueur < 0){
            mouvementsJoueurs.value.VersMajeurs.push(joueur);         
          }          
        } else {
          if(indexeJoueur >= 0){
            mouvementsJoueurs.value.VersMajeurs.splice(indexeJoueur, 1);     
          }          
        }
          
      } else {

        const indexeJoueur = mouvementsJoueurs.value.VersMineurs.findIndex(j => j.Nom == joueur.Nom);

        if(joueur.Selected){          
          if(indexeJoueur < 0){
            mouvementsJoueurs.value.VersMineurs.push(joueur);          
          }          
        } else {
          if(indexeJoueur >= 0){
            mouvementsJoueurs.value.VersMineurs.splice(indexeJoueur, 1);     
          }          
        }
      }   
      
      afficherDeltaMouvement();       
    }
    

    const afficherDeltaMouvement = () => {
      equipeMouvements.value = JSON.parse(JSON.stringify(equipe.value));
      
      //Retirer les joueurs qui partent dans les mineurs
      mouvementsJoueurs.value.VersMineurs.forEach(joueur => {
        const indexe = equipeMouvements.value.JoueursMajeurs.findIndex(j => j.Nom == joueur.Nom);
        if(indexe >=0 ){
          equipeMouvements.value.JoueursMajeurs.splice(indexe, 1);
        }
      });

      //Ajouter les joueurs qui montenet dans les majeurs
      mouvementsJoueurs.value.VersMajeurs.forEach(joueur => {
        const indexe = equipeMouvements.value.JoueursMajeurs.findIndex(j => j.Nom == joueur.Nom);
        if(indexe < 0 ){
          equipeMouvements.value.JoueursMajeurs.push(joueur);
        }
      });

      //Calculer le différentiel vs. l'équipe avant le move
      let masse = 0;
      let pts = 0;
      let nbAttaquants = 0;
      let nbDefenseurs = 0;
      let nbGardiens = 0;

      equipeMouvements.value.JoueursMajeurs.forEach(joueur => {
        masse += parseInt(parseFloat(joueur.Sal.replace(",",".")) * 1000);
        pts += parseInt(joueur.P);

        if(joueur.Pos == "A"){
          nbAttaquants++;
        }else if(joueur.Pos == "D"){
          nbDefenseurs++;
        } else {
          nbGardiens++;
        }
      });

      nouveauNbAttaquants.value = nbAttaquants;
      nouveauNbDefenseurs.value = nbDefenseurs;
      nouveauNbGardiens.value = nbGardiens;

      nouvelleMasseSalariale.value = parseFloat(masse / 1000);       
      nouvelleMasseDisponible.value = ((equipe.value.PlafondSalarial * 1000 - masse) / 1000);
      
      const diffPoints = pts - equipe.value.PointsPool;
      deltaPts.value = diffPoints > 0 ? "+" + diffPoints : diffPoints;
    }

    const definirDataTables = (selecteurTable) => {
        $(selecteurTable).DataTable({
          "paging": false,
          "info": false,
          "searching": false,
          "order-column": false,
          "language": {
            "decimal": ","
          },
          responsive: {
              details: {
                  type: 'column',
                  target: 'td.control'
              }
          },
          columnDefs: [             
            {
              className: 'control',
              orderable: false,
              width: "20px",
              targets:   -1
            },            
            { "type": "numeric-comma", "targets": 6 },
            {orderable: false, targets: 7},
            {orderable: false, targets: 8},
            {orderable: false, targets: 9},
            {orderable: false, targets: 10},
            {orderable: false, targets: 11},
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: 1 },
            { responsivePriority: 6, targets: 2 },                    
            { responsivePriority: 7, targets: 3 },
            { responsivePriority: 5, targets: 4 },            
            { responsivePriority: 4, targets: 5 },
            { responsivePriority: 3, targets: 6 },
            { responsivePriority: 5, targets: 7 }
          ],  
          "order":[[ 1, "asc" ], [ 6, "desc" ]]                                     
         });     
    };

    const obtenirCorpsFenetreMouvementJoueurs = () => {
      if(erreurMouvementJoueur.value){
        return "Une erreur est survenue lors du mouvement de joueurs. Veuillez réessayer."
      }

      if(estMouvementJoueurEnCours.value){
        return `<div class="d-flex flex-column align-items-center"><span class="fas fa-cog fa-spin my-4" style="font-size: 3rem" aria-hidden="true"></span><span>Pas de panique. Les gars font leurs valises...</span></div>`
      } else {
        return "Le mouvement de joueurs est complété."
      }
    }

    const estMouvementMasseValide = (estValide) => {
      estMouvementsMasseSalarialeValide.value = estValide
    }

    const estAlignementValide = (estValide) => {
      estMouvementsAlignementValide.value = estValide
    }

    return {
      equipe,
      estEquipePoolerCourant,
      equipeMouvements,
      titrePage,
      estObjetVide,
      obtenirStatut,
      obtenirTexteStatut,
      estMouvementsActifs,
      peutConfirmerMouvement,
      confirmerMouvements,
      activerDesactiverMouvementPersonnel,
      effectuerMouvementJoueur,
      mouvementsJoueurs,
      nouvelleMasseSalariale,
      nouvelleMasseDisponible,
      nouveauNbAttaquants,
      nouveauNbDefenseurs,
      nouveauNbGardiens,
      deltaPts,
      estAlignementValide,
      estMouvementMasseValide,
      fenetreMessage,
      estMasseSalarialeValide,
      estMouvementJoueurEnCours,
      obtenirCorpsFenetreMouvementJoueurs,
      rafraichirContenu,
      erreurMouvementJoueur,
      estAdmin, 
      activerEchange
    };
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#Actions{
  margin-bottom: var(--tc-32-24-val);
}


  .joueurs-majeurs, .joueurs-mineurs, .choix-repechage{
    border-bottom: 2px solid #DBDBDB;
    border-top: 2px solid #DBDBDB;
  }

  .joueurs-majeurs, .joueurs-mineurs, .mouvements-joueurs, .choix-repechage{
    margin-bottom: var(--tc-48-40-val);
  }

  .choix-repechage{    
    background-color: #fff;
  }
  .conteneur-choix{
    padding: 1rem .5rem .5rem .5rem;
  }

  .sommaire{
    margin-top: var(--tc-48-40-val);
  }

  .sommaire .card{
    margin-right: var(--tc-32-24-val);
    margin-bottom: var(--tc-48-40-val);
  }
  
  .sommaire .card:last-of-type{
    margin-right: 0;
  }

  .mouvements-joueurs .card{
    margin-bottom: 0;
  }


  .colonne-sommaire{
    display: flex;
    flex-direction: column;
    margin-right: var(--tc-32-24-val);
  }

  .colonne-sommaire:last-of-type{
    margin-right: 0;
  }

  .sommaire-mouvements .libelle{
    font-weight: 500;
  }

  .colonne-sommaire .libelle,   .colonne-sommaire .valeur, .sommaire-mouvements{
    font-size: var(--text-base-val);
  }

  .pts{
    font-weight: 500;
  }

.sommaire-mouvements ul{
  margin-bottom: 0;
}

.sommaire-mouvements .bloc-infos{
  margin-top: var(--tc-16-12-val);
  margin-right: var(--tc-32-24-val);
  margin-bottom: var(--tc-16-12-val);
  display: flex;
  flex-direction: column;  
}

.mouvements-joueurs .zone-boutons{
  margin-top: var(--tc-16-12-val);
}

.sommaire-mouvements .bloc-infos:last-of-type{
    margin-right: 0;
  }

  .btn.mouvement{
    margin: 0 8px 0 -8px;
  }

  .btn.mouvement .fas{
    width: 14px;
  }

  tr.selected .btn.mouvement .fas:before{
    content: "\f05e";
  }

  a.statut{
    font-family: "Font Awesome 5 Free";
    margin-left: .25rem;
    color:inherit;
    text-decoration: none;
  }

  a.statut > span{
    margin-left: .25rem;
  }

  a.statut .mineurs::before{
    content:"\f0d7";
    color: var(--couleur-erreur);
  }

  a.statut .taxi::before{
    content:"\f1ba";
  }

  a.statut .blesse::before{
    content:"\f0fe";
    color: var(--couleur-erreur);
  }

  a.statut .elc{
    font-family: var(--bs-font-sans-serif);
    font-size: 14px;
  }
</style>
