<template>
  <div class="card">
    <div class="card-body">
      <div class="card-title">Masse salariale<span aria-hidden="true" :class="[ estMasseValide() ? 'valide' : 'invalide', 'fas statut']"></span></div>
      <div class="d-flex">
        <div class="colonne-sommaire">
          <span class="libelle">Plafond</span>
          <span class="valeur">{{formaterNombre(plafond)}}</span>
        </div>
        <div class="colonne-sommaire">
          <span class="libelle">Total</span>
          <span class="valeur">{{formaterNombre(masse)}}</span>
        </div>
        <div class="colonne-sommaire">
          <span class="libelle">Disponible</span>
          <span class="valeur">{{formaterNombre(disponible)}}</span>
        </div>
      </div>                
    </div>
  </div>
</template>

<script>
export default {
  name: "SommaireSalaire",
  props: {
    plafond: Number,
    masse: Number, 
    disponible: Number,
    estValide: Boolean
  },
  setup(props, { emit }) {

    const formaterNombre = (nombre) => {
        return nombre.toString().replace(".", ",");
    }

    const estMasseValide = () => {
        const estValide = props.disponible >= 0;
        emit('estMouvementMasseValide', estValide);
        return estValide;
    }

    return {
      formaterNombre,
      estMasseValide
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
