import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Picks from "@/views/Picks.vue";
import Equipe from "@/views/Equipe.vue";
import JoueursTous from "@/views/JoueursTous.vue";
import Repechage from "@/views/Repechage.vue";
import Echange from "@/views/Echange.vue";
import RetourLogin from "@/views/RetourLogin.vue";
//import Login from "@/views/Login.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Poolombier - Classement"
    }
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/picks",
    name: "Picks",
    component: Picks
  },
  {
    path: "/joueurs",
    name: "Joueurs",
    component: JoueursTous
  },  
  {
    path: "/repechage",
    name: "Repêchage",
    component: Repechage
  },    
  {
    path: "/echange",
    name: "Échange",
    component: Echange
  },    
  {
    path: "/retourlogin",
    name: "Retour login",
    component: RetourLogin
  },    
  {
    path: "/equipe/:idPooler",
    name: "Equipe",
    component: Equipe,
    props: true,
    meta: {
      title: "Poolombier -"
    }

  }

/*  {
    path: "/login",
    name: "Connexion",
    component: Login,
  },*/

];


const router = createRouter({
  history: createWebHistory(),
  routes  
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Poolombier';
  next();
});

export default router;