import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---

import 'bootstrap'
import '@popperjs/core'
import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '@fortawesome/fontawesome-free/css/solid.min.css'
import 'datatables.net-dt'
import 'datatables.net-responsive-dt'
import 'datatables.net-searchbuilder-dt/js/searchBuilder.dataTables.js'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
import 'datatables.net-searchbuilder-dt/css/searchBuilder.dataTables.min.css'
import './assets/main.css' //TODO tenter de savoir pkoi ça fonctionne même sans l'import... vérifier suite au build.
import './assets/main'

const app = createApp(App).use(router)
app.mount('#app')